import { defineStore } from 'pinia'

// Pinia Store
export const useClaimStore = defineStore({
  id: 'claim',
  persist: [
    {
      paths: [
        // ui state
        'showTip'
        // claim state
        ,'id'
        ,'claimId'
        ,'lastSaved'
        ,'touched' // used to track whether a user interacted with date fields
        // general claim info
        ,'terms'
        ,'account'
        ,'policy'
        ,'claimType'
        ,'attachments'
        ,'signature'
        ,'checklist'
        // claim forms
        ,'unemployment'
        ,'disability'
        ,'life'
        ,'milestone'
        ,'property'
        ,'illness'
      ],
      storage: localStorage,
    },
  ],
  state: () => ({
    // ui state
    showTip: true,
    // if IDs are set, we're working with a saved claim
    id: null,
    claimId: null,
    lastSaved: null,
    touched: {
      milestone_event_date: false,
      life_spouse_dob: false,
      life_date_death: false,
      unemploy_date: false,
      property_loss_date: false,
      illness_event_date: false,
      disability_event_date: false,
    },
    // claim form
    terms: {
      agree: false,
      timestamp: null,
      userAgent: null,
    },
    account: null,
    policy: null,
    claimType: null,
    attachments: [], // must be empty array by default
    signature: null,
    // claim form checklists
    checklist: {
      L: [ // Life
        { idx: 0, checked: false, label: { en: 'Physician Statement completed by a doctor', fr: 'Formulaire de déclaration rempli par un médecin' }},
        { idx: 1, checked: false, label: { en: 'Death certificate', fr: 'Certificat de décès' } }
      ],
      D: [ // Disability
        { idx: 0, checked: false, label: { en: 'Employer Statement completed by your employer', fr: 'Formulaire de déclaration rempli par votre employeur' }},
        { idx: 1, checked: false, label: { en: 'Physician Statement completed by your doctor', fr: 'Formulaire de déclaration rempli par votre médecin traitant' }}
      ],
      U: [ // Unemployment
        { idx: 0, checked: false, label: { en: 'Record of Employment (ROE) from your employer', fr: 'Relevé d’emploi (RE) de votre employeur' }},
        { idx: 1, checked: false, label: { en: 'Employer Statement completed by your employer', fr: 'Formulaire de déclaration rempli par votre employeur' }}
      ],
      P: [ // Property
        { idx: 0, checked: false, label: { en: 'Property loss form', fr: 'Formulaire de perte matérielle' }},
        { idx: 1, checked: false, label: { en: 'Sale receipts, invoices and other proof of purchases', fr: 'Reçus, factures et autres preuves d’achat' }}
      ],
      C: [ // Critical Illness
        { idx: 0, checked: false, label: { en: 'Physician Statement completed by your doctor', fr: 'Formulaire de déclaration rempli par votre médecin traitant' }}
      ],
      // milestone
      R: [ // Retirement
        { idx: 0, checked: false, label: { en: 'Letter from your employer indicating retirement, or Record of Employment showing employment status', fr: 'Lettre de votre employeur indiquant votre départ à la retraite ou relevé d’emploi montrant votre statut d’emploi' } }
      ],
      B: [ // Birth/Adoption of child
        { idx: 0, checked: false, label: { en: 'Birth certificate, or adoption papers', fr: 'xxxxx' } }
      ],
      DM: [ // Discharge of Mortgage
        { idx: 0, checked: false, label: { en: 'Mortgage statement showing final payment', fr: 'Relevé hypothécaire montrant le paiement final' } }
      ],
      M: [ // Marriage
        { idx: 0, checked: false, label: { en: 'Marriage certificate', fr: 'Certificat de mariage' } }
      ],
      CM: [ // Childs Marriage
        { idx: 0, checked: false, label: { en: 'Marriage certificate', fr: 'Certificat de mariage' } }
      ],
      NHP: [ // New Home Purchase
        { idx: 0, checked: false, label: { en: 'Real estate purchase agreement, or deed of trust', fr: 'xxxxx' } }
      ],
      GPC: [ // Graduation/Certification
        { idx: 0, checked: false, label: { en: 'Degree/Diploma, or documentation of professional certification/designation', fr: 'Diplôme ou documentation relative à la certification / la désignation professionnelle' } }
      ],
    },
    // claim form: LIFE
    life: {
      name: null,
      dob: null,
      mailing_address: null,
      phone: null,
      spouse: {
        name: null,
        dob: null,
        mailing_address: null,
      },
      deceased: {
        name: null,
        date_death: null,
        cause: null,
        cause_extra: null,
      },
    },
    // claim form: DISABILTY
    disability: {
      name: null,
      dob: null,
      mailing_address: null,
      phone: null,
      event_date: null,
    },
    // claim form: UNEMPLOYMENT
    unemployment: {
      name: null,
      dob: null,
      mailing_address: null,
      phone: null,
      reason: null,
      qualify: null,
      qualify_reason: null,
      date_start: null,
      job_title: null,
      employer: {
        name: null,
        address: null,
      }
    },
    // claim form: MILESTONE
    milestone: {
      name: null,
      dob: null,
      mailing_address: null,
      phone: null,
      event_date: null,
    },
    // PROPERTY
    property: {
      name: null,
      dob: null,
      mailing_address: null,
      phone: null,
      loss: {
        type: null,
        date: null,
        degree: null,
        address: null,
        reported: null,
        incident: null,
        contact: null,
        details: null,
      }
    },
    // CRITICAL ILLNESS
    illness: {
      name: null,
      dob: null,
      mailing_address: null,
      phone: null,
      event_date: null,
    },
  }),
  actions: {
    async touchDates() {
      this.touched = {
        milestone_event_date: true,
        life_spouse_dob: true,
        life_date_death: true,
        unemploy_date: true,
        property_loss_date: true,
        illness_event_date: true,
        disability_event_date: true,
      }
    },
    async resetClaim() {
      this.id = null
      this.claimId = null
      this.lastSaved = null
      this.touched = {
        milestone_event_date: false,
        life_spouse_dob: false,
        life_date_death: false,
        unemploy_date: false,
        property_loss_date: false,
        illness_event_date: false,
        disability_event_date: false,
      }
      this.terms = {
        agree: false,
        timestamp: null,
        userAgent: null,
      }
      this.account = null
      this.policy = null
      this.claimType = null
      this.attachments = []
      this.signature = null
      this.checklist = {
        L: [ // Life
          { idx: 0, checked: false, label: { en: 'Physician Statement completed by a doctor', fr: 'Formulaire de déclaration rempli par un médecin' }},
          { idx: 1, checked: false, label: { en: 'Death certificate', fr: 'Certificat de décès' } }
        ],
        D: [ // Disability
          { idx: 0, checked: false, label: { en: 'Employer Statement completed by your employer', fr: 'Formulaire de déclaration rempli par votre employeur' }},
          { idx: 1, checked: false, label: { en: 'Physician Statement completed by your doctor', fr: 'Formulaire de déclaration rempli par votre médecin traitant' }}
        ],
        U: [ // Unemployment
          { idx: 0, checked: false, label: { en: 'Record of Employment (ROE) from your employer', fr: 'Relevé d’emploi (RE) de votre employeur' }},
          { idx: 1, checked: false, label: { en: 'Employer Statement completed by your employer', fr: 'Formulaire de déclaration rempli par votre employeur' }}
        ],
        P: [ // Property
          { idx: 0, checked: false, label: { en: 'Property loss form', fr: 'Formulaire de perte matérielle' }},
          { idx: 1, checked: false, label: { en: 'Sale receipts, invoices and other proof of purchases', fr: 'Reçus, factures et autres preuves d’achat' }}
        ],
        C: [ // Critical Illness
          { idx: 0, checked: false, label: { en: 'Physician Statement completed by your doctor', fr: 'Formulaire de déclaration rempli par votre médecin traitant' }}
        ],
        // milestone
        R: [ // Retirement
          { idx: 0, checked: false, label: { en: 'Letter from your employer indicating retirement, or Record of Employment showing employment status', fr: 'Lettre de votre employeur indiquant votre départ à la retraite ou relevé d’emploi montrant votre statut d’emploi' } }
        ],
        B: [ // Birth/Adoption of child
          { idx: 0, checked: false, label: { en: 'Birth certificate, or adoption papers', fr: 'Certificat de naissance ou documents d’adoption' } }
        ],
        DM: [ // Discharge of Mortgage
          { idx: 0, checked: false, label: { en: 'Mortgage statement showing final payment', fr: 'Relevé hypothécaire montrant le paiement final' } }
        ],
        M: [ // Marriage
          { idx: 0, checked: false, label: { en: 'Marriage certificate', fr: 'Certificat de mariage' } }
        ],
        CM: [ // Childs Marriage
          { idx: 0, checked: false, label: { en: 'Marriage certificate', fr: 'Certificat de mariage' } }
        ],
        NHP: [ // New Home Purchase
          { idx: 0, checked: false, label: { en: 'Real estate purchase agreement, or deed of trust', fr: 'Contrat d’achat de biens immobiliers ou acte de fiducie' } }
        ],
        GPC: [ // Graduation/Certification
          { idx: 0, checked: false, label: { en: 'Degree/Diploma, or documentation of professional certification/designation', fr: 'Diplôme ou documentation relative à la certification / la désignation professionnelle' } }
        ],
      }
      // LIFE
      this.life = {
        name: null,
        dob: null,
        mailing_address: null,
        phone: null,
        spouse: {
          name: null,
          dob: null,
          mailing_address: null,
        },
        deceased: {
          name: null,
          date_death: null,
          cause: null,
          cause_extra: null,
        },
      }
      // DISABILTY
      this.disability = {
        name: null,
        dob: null,
        mailing_address: null,
        phone: null,
        event_date: null,
      }
      // UNEMPLOYMENT
      this.unemployment = {
        name: null,
        dob: null,
        mailing_address: null,
        phone: null,
        reason: null,
        qualify: null,
        qualify_reason: null,
        date_start: null,
        job_title: null,
        employer: {
          name: null,
          address: null,
        }
      }
      // MILESTONE
      this.milestone = {
        name: null,
        dob: null,
        mailing_address: null,
        phone: null,
        event_date: null,
      }
      // PROPERTY
      this.property = {
        name: null,
        dob: null,
        mailing_address: null,
        phone: null,
        loss: {
          type: null,
          date: null,
          degree: null,
          address: null,
          reported: null,
          incident: null,
          contact: null,
          details: null,
        }
      }
      // CRITICAL ILLNESS
      this.illness = {
        name: null,
        dob: null,
        mailing_address: null,
        phone: null,
        event_date: null,
      }
      return true
    },
    // loads a previously saved form into active state
    async loadClaim() {
      const data = await $fetch(`/api/claim/${this.id}`)
      this.lastSaved = data.updated
      this.claimId = data.claim_id
      this.claimType = data.claim_type
      if(data.terms) this.terms = data.terms
      if(data.account) this.account = data.account
      if(data.policy) this.policy = data.policy
      if(data.attachments) this.attachments = data.attachments
      if(data.signature) this.signature = data.signature
      // claim forms
      switch(data.claim_type) {
        case 'L': this.life = data.claim; break;
        case 'U': this.unemployment = data.claim; break;
        case 'D': this.disability = data.claim; break;
        case 'P': this.property = data.claim; break;
        case 'C': this.illness = data.claim; break;
        // milestone
        case 'R':   // Retirement
        case 'B':   // Birth/Adoption of child
        case 'DM':  // Discharge of Mortgage
        case 'M':   // Marriage
        case 'CM':  // Childs Marriage
        case 'NHP': // New Home Purchase
        case 'GPC': // Graduation/Certification
          this.milestone = data.claim; break;
      }
      return true
    },
  }
})
