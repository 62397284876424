/*
IMPORTANT:
Be very careful when modifying this file.
Some blocks of content are broken into parts, and so punctuation and spacing are important here!
*/

export default {
  links: {
    privacy: 'https://transglobalinsurance.ca/fr/politique-de-confidentialite',
    terms: 'https://transglobalinsurance.ca/fr/termes-et-conditions',
  },
  auth: {
    terms: {
      A: 'Lisez notre',
      B: 'avis sur la vie privée',
      C: 'pour savoir comment nous utilisons vos données et comment nous les protégeons. En appuyant pour vous connecter, vous acceptez les',
      D: 'modalités',
      E: ' de TGI.',
    },
    email: 'Adel',
    password: 'Mot de passe',
    cancel: 'Annuler',
    back: 'Retour',
    update: 'Mise à jour',
    enroll: 'S’inscrire',
    submit: 'Soumettre',
    signOut: 'Déconnexion sécuritaire...',
    login: {
      signIn: 'Connexion',
      forgot: 'Mot de passe oublié?',
      new: 'Vous n’avez jamais fait de réclamation en ligne?',
      enroll: 'S’inscrire maintenant',
    },
    forgot: {
      title: 'Mot de passe oublié',
      exists: 'Si un compte avec cet adel existe, vous recevrez bientôt un message électronique.',
      resetPassword: 'Reprogrammer le mot de passe',
      updatePassword: 'Mettre le mot de passe à jour',
      newPassword: 'Nouveau mot de passe',
      success_A: 'C’est réussi. Votre mot de passe a été mis à jour. Vous pouvez',
      success_B: 'vous connecter'
    },
    passStrength: {
      pick: 'Choisir un mot de passe',
      lower: 'Au moins une lettre minuscule',
      upper: 'Au moins une lettre majuscule',
      numeric: 'Au moins un chiffre',
      length: 'Au moins 8 caractères',
    },
    errors: {
      unknown: 'Une erreur inconnue s’est produite.',
      missingEmail: 'Veuillez entrer une adresse électronique.',
      enterEmail: 'Veuillez entrer une adresse électronique valide.',
      enterPassword: 'Veuillez entrer votre mot de passe.',
      invalidEmail: 'Adresse électronique non valide',
      invalidPass: 'Mot de passe non valide. Doit avoir un minimum de 8 caractères.',
      passwordMinimum: 'Votre mot de passe ne correspond pas aux exigences minimales.',
      troubleForgot: 'Nous avons de la difficulté à vous connecter. Essayez la fonction « Mot de passe oublié » si vous continuez à avoir ce problème.',
      troublePassword: 'Nous avons de la difficulté à mettre votre mot de passe à jour. Veuillez réessayer.',
      troubleEmail: 'Nous avons de la difficulté à vous envoyer un message électronique. Veuillez réessayer.',
      troubleSending: 'Nous avons de la difficulté à envoyer le courriel de réinitialisation de mot de passe. Veuillez réessayer.',
    },
  },
  menu: {
    dashboard: 'Accueil',
    policies: 'Polices',
    claim: 'Réclamation',
    claims: 'Réclamations',
    help: 'Aide',
    settings: 'Paramètres',
    signOut: 'Déconnexion',
  },
  dashboard: {
    title: 'Accueil',
    activeAccounts: 'Comptes actifs',
    openClaims: 'Réclamations ouvertes',
  },
  accounts: {
    title: 'Mes comptes',
    partner: 'Partenaire',
    accountId: 'Identifiant du compte',
    enrollDate: 'Date d’adhésion'
  },
  transactions: {
    title: 'Transactions récentes',
    date: 'Date',
    amount: 'Montant',
    type: 'Type',
    account: 'Compte',
    types: {
      debit: 'Débit',
      credit: 'Crédit',
      refund: 'Crédit', // refund is actuall "Remboursement" but thats too long
    },
    noRecent: 'Vous n’avez pas de transactions récentes.',
  },
  policies: {
    title: 'Polices',
    number: 'Numéro',
    partner: 'Partenaire',
    enrollDate: 'Date d’adhésion',
    status: 'Statut',
    certificate: 'Certificat',
    download: 'Télécharger',
  },
  claim: { title: 'Réclamation' },
  claims: {
    title: 'Réclamations',
    myClaims: 'Mes réclamations',
    number: 'Numéro',
    accountNumber: 'Numéro de compte',
    policy: 'Police',
    partner: 'Partenaire',
    claimNumber: 'Numéro de la réclamation',
    claimDetails: 'Détails de la réclamation',
    claimDate: 'Date de la réclamation',
    claimType: 'Type de la réclamation',
    lossDate: 'Date de la perte',
    type: 'Type',
    status: 'Statut',
    reason: 'Raison',
    formDetails: 'Détails du formulaire',
    savedClaims: 'Réclamations enregistrées',
    savedClaimsNone: 'Vous n’avez aucune réclamations enregistrées.',
    startClaim: 'Commencer une nouvelle réclamation',
    attachments: 'Pièces jointes',
    claimTimeline: 'Chronologie des réclamations',
    noneFound: 'Aucune réclamation trouvée',
  },
  help: {
    title: 'Aide',
    commonQuestions: 'Foire aux questions',
    contactInfo: 'Coordonnées',
    mailingAddress: 'Adresse postale',
    phone: 'Téléphone',
    email: 'Adel',
    phone_1: 'Du lundi au vendredi',
    phone_2: '8h - 17h heure de l’est',
  },
  settings: {
    title: 'Paramètres',
    sorry: 'Désolé, rien à voir ici.',
    working: 'Nous travaillons sur de nouvelles fonctionnalités pour améliorer votre expérience.',
  },
  claimView: {
    spouseName: 'Nom du conjoint ou de l’exécuteur testamentaire',
    spouseBirthdate: 'Date de naissance du conjoint ou de l’exécuteur testamentaire',
    spouseAddress: 'Adresse postale du conjoint ou de l’exécuteur testamentaire',
    benefitsQualify: 'Qualité pour les avantages ?',
    reason: 'Raison',
    printOrSave: 'Imprimer ou enregistrer au format PDF',
  },
  newClaim: {
    title: 'Nouvelle réclamation',
    leaveWithoutSave: 'Vous partez sans sauvegarder?',
    leaveConfirm: 'Voulez-vous quitter cette page sans sauvegarder votre réclamation?',
    yes: 'Oui',
    no: 'Non',
    okay: 'Ok',
    cancel: 'Annuler',
    clear: 'Effacer',
    undo: 'Défaire',
    back: 'Retour',
    next: 'Suivant',
    print: 'Imprimer',
    upload: 'Téléverser',
    delete: 'Supprimer',
    choose: 'Choisir',
    chooseFiles: 'Choisir les fichiers',
    sending: 'Envoi...',
    pleaseWait: 'S’il te plaît attends.',
    claimReceived: 'Réclamation reçue.',
    advisorReview: 'Un de nos conseillers de TGI commencera à passer votre réclamation en revue.',
    lastSaved: 'Dernier enregistrement :',
    never: 'jamais',
    saving: 'Économiser...',
    save: 'Enregistrer',
    success: 'Succès',
    completed: 'complétés',
    confirmed: 'Confirmé',
    cancelClaim: 'Annuler la réclamation',
    claimCancelled: 'Réclamation annulée',
    claimCleared: 'Toutes les données sur la réclamation ont été effacées.',
    filesUploaded: 'Fichiers téléchargés',
    deletedFile: 'Fichier supprimé',
    tip: {
      part_1: 'Conseil :',
      part_2: 'Vous pouvez passer d’une étape à l’autre en utilisant les touches',
      part_3: 'Numéro',
      part_4: 'ou les',
      part_5: 'Flèches',
      part_6: 'sur votre clavier.',
      part_7: 'Pour la meilleure expérience possible, nous vous recommandons d’utiliser un ordinateur fixe ou un ordinateur portable.',
    },
    instructions: {
      instructions: 'Instructions',
      warning: 'Avertissement :',
      authorization: 'Déclaration et autorisation :',
      accept: 'Je comprends et j’accepte',
      part_1: 'Vous devez lire attentivement toutes les questions du formulaire et y répondre avec précision. Nous pourrions refuser de traiter les formulaires qui sont incomplets.',
      part_2: 'Toutes les personnes qui, en connaissance de cause et ayant l’intention de commettre une fraude contre une compagnie d’assurance ou toute autre personne, font une demande d’assurance ou une déclaration de réclamation contenant de fausse informations ou dissimulent sciemment, dans le but d’induire en erreur, des informations sur les faits relatifs à leur réclamation,  commettent une fraude à l’assurance, ce qui peut constituer un crime et peut faire l’objet de sanctions pénales.',
      part_3: 'Je certifie que les informations fournies ici sont vraies et exactes. J’AUTORISE mon employeur, mon médecin, mon hôpital, mon assureur, tout organisme d’application de la loi, tout service d’incendie ou toute autre organisation ou personne ayant des dossiers, des données ou des informations concernant cette réclamation à fournir ces dossiers, données ou informations à TRANS GLOBAL INSURANCE COMPANY ET TRANS GLOBAL LIFE INSURANCE COMPANY ou à un(e) représentant(e) autorisé(e) (collectivement « TGI »), selon les besoins. J’autorise également TGI à partager les informations de ce formulaire avec n’importe quelle tierce partie, tel que TGI le juge nécessaire pour traiter ma demande. Je comprends qu’en signant cette autorisation, je consens à que ces informations ne soient plus confidentielles. Je comprends que je peux annuler cette autorisation à n’importe quel moment, mais que si je le fais, TGI ne sera plus en mesure d’évaluer ma réclamation et ne pourra pas me verser de prestations. Toute reproduction de cette autorisation devra être considérée comme étant aussi valide que l’originale.',
    },
    account: {
      accountPolicy: 'Compte / police',
      whichAccount: 'Pour quel compte soumettez-vous une réclamation?',
    },
    claim: {
      claimInfo: 'Informations sur la réclamation',
    },
    documents: {
      supporting: 'Pièces justificatives',
      required: 'Votre réclamation exige des pièces justificatives. Veuillez cocher la case ci-dessous pour indiquer que vous avez téléversé le ou les documents obligatoires :',
      appearHere: 'Les pièces téléversées apparaîtront ici.',
      deleteFile: 'Effacer ce fichier?',
      pleaseAttach: 'Veuillez joindre vos pièces justificatives ou vos photos.',
      upload: {
        desktop_1: 'Glissez et déposez',
        desktop_2: 'les fichiers ici ou utilisez le bouton',
        mobile_1: 'Sélectionnez les fichiers en utilisant le',
        part_3: 'les fichiers.',
        part_4: 'Appuyez sur',
        part_5: 'pour transmettre les fichiers de manière sécuritaire.'
      },
    },
    chooseAccount: 'Choisissez un compte',
    selectAccount: 'Sélectionnez un compte',
    choosePolicy: 'Choisissez une police',
    selectPolicy: 'Sélectionnez un police',
    selectClaimType: 'Sélectionnez un type de réclamation',
    signature: 'Signature',
    drawSignature: 'Utilisez votre doigt ou votre souris pour signer dans la boîte ci-dessous.',
    signatureTip: {
      part_1: 'Après la soumission de votre réclamation, une copie numérique de votre signature sera codée et entreposée de manière sécuritaire, dans le cadre de votre réclamation. Nous accèderons à votre signature seulement dans le cadre du traitement de votre réclamation. Dans le cas peu probable d’une violation de données, votre signature numérique sera protégée et personne d’autres que les conseillers aux réclamations de TGI ne peuvent y avoir accès. Pour plus d’informations, veuillez consulter notre',
      part_2: 'politique de confidentialité',
    },
    uploadTip: {
      part_1: 'Les fichiers que vous téléversez sont transmis de manière sécuritaire et cryptés pour leur stockage. Nous accèderons à vos documents seulement dans le cadre du traitement de votre réclamation. Pour plus d’informations, veuillez consulter notre',
      part_2: 'politique de confidentialité',
    },
    submitClaim: 'Soumettre la réclamation',
    certifyClaim: 'Je certifie que les informations fournies ici sont vraies et exactes. J’AUTORISE mon employeur, mon médecin, mon hôpital, mon assureur, tout organisme d’application de la loi, tout service d’incendie ou toute autre organisation ou personne ayant des dossiers, des données ou des informations concernant cette réclamation à fournir ces dossiers, données ou informations à TRANS GLOBAL INSURANCE COMPANY ET TRANS GLOBAL LIFE INSURANCE COMPANY ou à un(e) représentant(e) autorisé(e) (collectivement « TGI »), selon les besoins. J’autorise également TGI à partager les informations de ce formulaire avec n’importe quelle tierce partie, tel que TGI le juge nécessaire pour traiter ma demande. Je comprends qu’en signant cette autorisation, je consens à que ces informations ne soient plus confidentielles. Je comprends que je peux annuler cette autorisation à n’importe quel moment, mais que si je le fais, TGI ne sera plus en mesure d’évaluer ma réclamation et ne pourra pas me verser de prestations. Toute reproduction de cette autorisation devra être considérée comme étant aussi valide que l’originale.',
    fallback: {
      part_1: 'Formulaire de réclamation non disponible',
      part_2: 'Nous travaillons à mettre ce formulaire de réclamation en ligne.',
      part_3: 'Communiquez avec l’un de nos conseillers de TGI au',
      part_4: ', pour lancer cette réclamation.',
    },
    formSteps: {
      part: 'Partie',
      step: 'Étape',
      partA: 'Partie A :',
      partB: 'Partie B :',
      partC: 'Partie C :',
      partD: 'Partie D :',
      partE: 'Partie E :',
      saveProgress: 'Vous pouvez enregistrer vos progrès et y revenir lorsque vous aurez rempli les formulaires adéquats.',
      claimantInformation: 'Informations sur la partie demanderesse',
      disabilityDetails: 'Détails sur l’invalidité',
      employerStatement: 'Déclaration de l’employeur',
      physicianStatement: 'Déclaration du médecin traitant',
      eventInformation: 'Informations sur l’événement', // milestone
      illnessDetails: 'Détails sur la maladie', // critical illness
      spouseInformation: 'Renseignements sur le conjoint ou l’exécuteur testamentaire', // life
      deceasedInformation: 'Informations sur la personne décédée', // life
      lossDetails: 'Détails sur la perte', // property
      propertyDetails: 'Détails sur les biens', // property
      employmentInformation: 'Informations sur l’emploi', // unemployment
      unionStatement: 'Déclaration du syndicat (si applicable)', // unemployment
    },
    formFields: {
      dateFormat: 'AAAA-MM-JJ',
      name: { label: 'Nom de la partie demanderesse', help: 'Prénom et nom' },
      birthdate: { label: 'Date de naissance', help: 'AAAA-MM-JJ' },
      address: { label: 'Adresse postale', help: 'Adresse complète, y compris la ville, la province et le code postal' },
      phone: { label: 'Numéro de téléphone', help: 'Y compris le code régional' },
      scanUpload: 'Scannez le document et téléversez-le à la prochaine étape, avec les autres pièces justificatives.',
      employerStatement: {
        step1: 'Téléchargez le formulaire de déclaration de l’employeur en format PDF.',
        step2: 'Demandez à votre employeur de remplir le formulaire.',
        downloadForm: 'Télécharger le formulaire',
      },
      physicianStatement: {
        step1: 'Téléchargez le formulaire de déclaration du médecin traitant en format PDF.',
        step2: 'Demandez à votre médecin traitant de remplir le formulaire.',
        downloadForm: 'Télécharger le formulaire',
      },
      unionStatement: {
        step1: 'Téléchargez le formulaire de déclaration du syndicat en format PDF.',
        step2: 'Demandez à un(e) représentant(e) syndical(e) de remplir le formulaire.',
        downloadForm: 'Télécharger le formulaire',
        unionMember: 'Si vous êtes membre d’un syndicat, veuillez demander à un(e) représentant(e) syndical(e) de remplir le formulaire.',
      },
      propertyStatement: {
        step1: 'Téléchargez le formulaire de perte matérielle en format PDF.',
        step2: 'Faites la liste de tous les biens inclus dans cette réclamation.',
        downloadForm: 'Télécharger le formulaire',
        receipts: 'Téléversez tous les reçus, toutes les factures et toutes les autres preuves d’achat comme pièces justificatives pour tous les biens.'
      },
      disability: {
        event_date: 'Date de l’accident / de la blessure',
      },
      illness: {
        event_date: 'Date du diagnostic'
      },
      life: {
        spouseName: 'Nom',
        spouseBirthdate: 'Date de naissance',
        spouseAddress: {label: 'Adresse postale', help: 'Adresse complète, y compris la ville, la province et le code postal' },
        deceasedName: 'Nom de la personne décédée',
        deathDate: 'Date du décès',
        deathCause: { label: 'Cause du décès', help: 'Veuillez donner les détails ci-dessous.' },
        deathDetails: 'Cause du décès (détails)'
      },
      milestone: {
        eventDate: 'Date de l’événement'
      },
      property: {
        lossType: { label: 'Type de perte', help: 'Incendie, vol, etc' },
        lossDate: 'Date de la perte',
        lossDegree: 'Degré de la perte',
        lossAddress: { label: 'Perte ayant eu lieu à quelle adresse?', help: 'Adresse complète, y compris la ville, la province et le code postal' },
        lossReported: 'Perte rapportée par :',
        lossDetails: { label: 'Comment la perte a-t-elle eu lieu?', help: 'Donnez tous les détails pertinents' },
        lossIncident: 'Numéro de dossier de la police ou des services d’incendie :',
        lossContact: { label: 'Coordonnées de la personne représentant la police / les services d’incendie, le département ou chargée de l’enquête :', help: 'y compris le numéro de téléphone et l’adresse' }
      },
      unemployment: {
        employerName: 'Nom de l’employeur',
        employerAddress: 'Adresse de l’employeur',
        jobTitle: 'Emploi ou titre du poste',
        unemploymentStartDate: 'Date du chômage involontaire',
        reason: 'Raison du chômage',
        causeDetails: 'Veuillez expliquer :',
        qualifiesForBenefits: { label: 'Êtes-vous éligible aux prestations d’assurance emploi pendant cette période de chômage?', help: 'Si non, veuillez donner des explications.' },
      }
    },
    errors: {
      noAccount: 'Vous devez sélectionner un compte.',
      noPolicy: 'Vous devez sélectionner une politique.',
      noSignature: 'Signature manquante.',
      noClaimType: 'Vous devez choisir le type de réclamation.',
      docsRequired: 'Pièces justificatives obligatoires.',
      missingDocs: 'Il vous manque des documents obligatoires.',
      pleaseUpload: 'Veuillez téléverser les pièces justificatives obligatoires.',
      missingFields: 'Vous avez oublié des champs obligatoires.',
      pleaseComplete: 'Veuillez passer votre demande en revue et remplir les champs qui sont surlignés.',
    },
    fieldErrors: {
      name: 'Entrez votre nom.',
      birthdate: 'Entrez votre date de naissance.',
      address: 'Entrez votre adresse.',
      phone: {
        required: 'Entrez votre numéro de téléphone.',
        integer: 'Seuls les numéros sont autorisés.',
        length: 'Le numéro de téléphone doit être à 10 chiffres.'
      },
      eventDate: 'Entrez la date à laquelle cet événement a eu lieu.',
      lossType: 'Entrez le type de la perte.',
      lossDate: 'Entrez la date de la perte.',
      lossDegree: 'Entrez le degré de la perte.',
      lossAddress: 'Entrez l’adresse de la perte.',
      lossReport: 'Entrez le nom de la personne qui a rapporté la perte.',
      lossDetails: 'Entrez les détails de la perte.',
      lossNumber: 'Entrez le numéro de l’incident.',
      lossContact: 'Entrez les coordonnées de la personne.',
      diagnosisDate: 'Entrez la date du diagnostic.',
      injuryDate: 'Entrer la date de l’accident / de la blessure.',
      spouseName: 'Entrez le nom du conjoint / de la conjointe ou de l’exécuteur(trice) testamentaire.',
      spouseBirthdate: 'Entrez la date de naissance du conjoint / de la conjointe.',
      spouseAddress: 'Entrez l’adresse du conjoint / de la conjointe ou de l’exécuteur(trice) testamentaire.',
      deathName: 'Entrez le nom de la personne décédée.',
      deathDate: 'Entrez la date du décès.',
      deathCause: 'Sélectionnez la cause du décès.',
      deathDetails: 'Entrez les détails sur la cause du décès.',
      employerName: 'Entrez le nom de l’employeur.',
      employerAddress: 'Entrez l’adresse de l’employeur.',
      jobTitle: 'Entrez le titre de votre poste.',
      unemployDate: 'Entrez la date du chômage.',
      unemployReason: 'Entrez la raison du chômage.',
      chooseOption: 'Choisissez une option.',
      provideReason: 'Fournir une raison.',
    },
  },
}
