// return a string in proper case; "Hello"
export function proper(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  })
}

// return a string with only the first word in proper case; "Hello world"
export function properFirst(str) {
  const lowerCaseStr = str.toLowerCase()
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1)
}

// return a string with all words in proper case; "Hello World"
export function properWords(str) {
  return str.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

// format a phone number
export function formatPhone(phone) {
  if (!phone) return '-'
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  return phone
}

// truncate a very long account number
export function truncAccountId(str) {
  if (str.length > 12) {
    return "..." + str.slice(-12);
  }
  return str
}

// Return account status in proper case and the applicable language
export function accountStatus(str, locale='en') {
  str = str.toLowerCase()
  const statuses = {
    active: { en: 'Active', fr: 'Actif' },
    cancelled: { en: 'Cancelled', fr: 'Annulé' },
    replaced: { en: 'Replaced', fr: 'Remplacé' },
  }
  const status = statuses[str]?.[locale] || str
  return status.toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase())
}

// Return claim status types in proper case and the applicable language
export function claimStatusType(str, locale='en') {
  str = str.toLowerCase()
  const statuses = {
    approved: { en: 'approved', fr: 'approuvé' },
    pending: { en: 'pending', fr: 'en attente' },
    closed: { en: 'closed', fr: 'fermé' },
    denied: { en: 'denied', fr: 'refusé' },
  }
  const status = statuses[str]?.[locale] || str
  return status.toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase())
}
