/*
IMPORTANT:
Be very careful when modifying this file.
Some blocks of content are broken into parts, and so punctuation and spacing are important here!
*/

export default {
  links: {
    privacy: 'https://transglobalinsurance.ca/about-us/privacy-policy',
    terms: 'https://transglobalinsurance.ca/terms-conditions',
  },
  auth: {
    terms: {
      A: 'Read our',
      B: 'Privacy Notice',
      C: 'to learn how we use and protect your data. By clicking to sign in, you agree to TGI’s',
      D: 'Terms & Conditions',
      E: '.',
    },
    email: 'Email',
    password: 'Password',
    cancel: 'Cancel',
    back: 'Back',
    update: 'Update',
    enroll: 'Enroll',
    submit: 'Submit',
    signOut: 'Securely signing you out…',
    login: {
      signIn: 'Sign in',
      forgot: 'Forgot Password?',
      new: 'New to online claims?',
      enroll: 'Enroll Now',
    },
    forgot: {
      title: 'Forgot Password',
      exists: 'If an account with that email exists, you will receive an email shortly.',
      resetPassword: 'Reset Password',
      updatePassword: 'Update Password',
      newPassword: 'New Password',
      success_A: 'Success. Your password has been updated. You may now',
      success_B: 'sign in'
    },
    passStrength: {
      pick: 'Pick a password',
      lower: 'At least one lowercase',
      upper: 'At least one uppercase',
      numeric: 'At least one numeric',
      length: 'Minimum 8 characters',
    },
    errors: {
      unknown: 'An unknown error occurred',
      missingEmail: 'Please enter an email address.',
      enterEmail: 'Please enter a valid email address.',
      enterPassword: 'Please enter your password.',
      invalidEmail: 'Invalid email address.',
      invalidPass: 'Invalid password. Must be at least 8 characters.',
      passwordMinimum: 'Your password does not meet the minimum requirements.',
      troubleForgot: 'We had trouble signing you in. Try the Forgot Password function if you continue to have issues.',
      troublePassword: 'We had trouble updating your password. Please try again.',
      troubleEmail: 'We had trouble sending you an email. Please try again.',
      troubleSending: 'We had trouble sending the password reset email. Please try again.',
    },
  },
  menu: {
    dashboard: 'Home',
    policies: 'Policies',
    claim: 'Claim',
    claims: 'Claims',
    help: 'Help',
    settings: 'Settings',
    signOut: 'Sign out',
  },
  dashboard: {
    title: 'Home',
    activeAccounts: 'Active Accounts',
    openClaims: 'Open Claims',
  },
  accounts: {
    title: 'My Accounts',
    partner: 'Partner',
    accountId: 'Account ID',
    enrollDate: 'Enroll Date'
  },
  transactions: {
    title: 'Recent Transactions',
    date: 'Date',
    amount: 'Amount',
    type: 'Type',
    account: 'Account',
    types: {
      debit: 'Debit',
      credit: 'Credit',
      refund: 'Refund',
    },
    noRecent: 'You have no recent transactions.',
  },
  policies: {
    title: 'Policies',
    number: 'Number',
    partner: 'Partner',
    enrollDate: 'Enroll Date',
    status: 'Status',
    certificate: 'Certificate',
    download: 'Download',
  },
  claim: {title: 'Claim'},
  claims: {
    title: 'Claims',
    myClaims: 'My Claims',
    number: 'Number',
    accountNumber: 'Account Number',
    policy: 'Policy',
    partner: 'Partner',
    claimNumber: 'Claim Number',
    claimDetails: 'Claim Details',
    claimDate: 'Claim Date',
    claimType: 'Claim Type',
    lossDate: 'Date of Loss',
    type: 'Type',
    status: 'Status',
    reason: 'Reason',
    formDetails: 'Form Details',
    savedClaims: 'Saved Claims',
    savedClaimsNone: 'You have no saved claims.',
    startClaim: 'Start a New Claim',
    attachments: 'Attachments',
    claimTimeline: 'Claim Timeline',
    noneFound: 'No claims found.',
  },
  help: {
    title: 'Help',
    commonQuestions: 'Commonly Asked Questions',
    contactInfo: 'Contact Information',
    mailingAddress: 'Mailing Address',
    phone: 'Phone',
    email: 'Email',
    phone_1: 'Monday to Friday',
    phone_2: '8am - 5pm MST (10am - 7pm EST)',
  },
  settings: {
    title: 'Settings',
    sorry: 'Sorry, nothing to see here.',
    working: 'We’re working on new features to enhance your experience.',
  },
  claimView: {
    spouseName: 'Spouse/Executor Name',
    spouseBirthdate: 'Spouse/Executor Date of Birth',
    spouseAddress: 'Spouse/Executor Mailing Address',
    benefitsQualify: 'Qualify for benefits?',
    reason: 'Reason',
    printOrSave: 'Print or Save as PDF',
  },
  newClaim: {
    title: 'New Claim',
    leaveWithoutSave: 'Leave without saving?',
    leaveConfirm: 'Are you sure you want to leave before saving your claim?',
    yes: 'Yes',
    no: 'No',
    okay: 'Okay',
    cancel: 'Cancel',
    clear: 'Clear',
    undo: 'Undo',
    back: 'Back',
    next: 'Next',
    print: 'Print',
    upload: 'Upload',
    delete: 'Delete',
    choose: 'Choose',
    chooseFiles: 'Choose Files',
    sending: 'Sending...',
    pleaseWait: 'Please wait.',
    claimReceived: 'Claim Received.',
    advisorReview: 'One of our TGI Advisors will begin reviewing your claim.',
    lastSaved: 'Last saved:',
    never: 'never',
    saving: 'Saving...',
    save: 'Save',
    success: 'Success',
    completed: 'completed',
    confirmed: 'Confirmed',
    cancelClaim: 'Cancel Claim',
    claimCancelled: 'Claim cancelled',
    claimCleared: 'All claim data has been cleared.',
    filesUploaded: 'Files Uploaded',
    deletedFile: 'Deleted file',
    tip: {
      part_1: 'Tip:',
      part_2: 'You can navigate steps using the',
      part_3: 'Number',
      part_4: 'or',
      part_5: 'Arrow',
      part_6: 'keys on your keyboard.',
      part_7: 'For the best experience, we recommend using a Desktop computer or Laptop.'
    },
    instructions: {
      instructions: 'Instructions',
      warning: 'Warning:',
      authorization: 'Declaration & Authorization:',
      accept: 'I Understand and Accept',
      part_1: 'It is important that you carefully read and answer all questions in the form. Incomplete forms may be declined without processing.',
      part_2: 'Any persons who knowingly and with the intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which may constitute a crime and may also be subject to civil penalties.',
      part_3: 'I certify that the information given here is true and correct. I AUTHORIZE my employer, physician, hospital, insurer, law enforcement agency, fire department or other organization, or person having any records, data or information concerning this claim to furnish such records, data or information to TRANS GLOBAL INSURANCE COMPANY AND TRANS GLOBAL LIFE INSURANCE COMPANY or its authorized representative (collectively “TGI”) as requested. I also authorize TGI to share the information on this form with whatever parties it deems necessary to process my claim. I understand that in executing this authorization, I waive the right for such information to be privileged. I understand that I can withdraw my consent at any time, but that if I do, TGI will not be able to assess my claim and will not pay benefits. A reproduction of this authorization shall be as valid as the original.',
    },
    account: {
      accountPolicy: 'Account/Policy',
      whichAccount: 'Which account are you submitting a claim for?',
    },
    claim: {
      claimInfo: 'Claim Information',
    },
    documents: {
      supporting: 'Supporting Documents',
      required: 'Your claim requires supporting documents. Please check to indicate that you have uploaded each required item:',
      appearHere: 'Uploads will appear here.',
      deleteFile: 'Delete this file?',
      pleaseAttach: 'Please attach any supportive documents or images.',
      upload: {
        desktop_1: 'Drag and drop',
        desktop_2: 'files here, or use the',
        mobile_1: 'Select files using the',
        part_3: 'button.',
        part_4: 'Press',
        part_5: 'to transmit the files securely.'
      },
    },
    chooseAccount: 'Choose an Account',
    selectAccount: 'Select an Account',
    choosePolicy: 'Choose a Policy',
    selectPolicy: 'Select a Policy',
    selectClaimType: 'Select a Claim Type',
    signature: 'Signature',
    drawSignature: 'Draw your signature inside this box, using your finger or mouse.',
    signatureTip: {
      part_1: 'On submitting, a digital copy of your signature is encrypted and securely stored as part of your claim. Your signature will only ever be accessed for the purpose of processing your claim. In the unlikely event of a data breach, your digital signature will remain protected, and cannot be read by anyone other than trusted TGI Claim Advisors. For additional information, see our',
      part_2: 'privacy policy',
    },
    uploadTip: {
      part_1: 'Files you upload are transmitted securely and encrypted at rest. Your documents will only ever be accessed for the purpose of processing your claim. For additional information, see our',
      part_2: 'privacy policy',
    },
    submitClaim: 'Submit Claim',
    certifyClaim: 'I certify that the information given here is true and correct. I AUTHORIZE my employer, physician, hospital, insurer, law enforcement agency, fire department or other organization, or person having any records, data or information concerning this claim to furnish such records, data or information to TRANS GLOBAL INSURANCE COMPANY and TRANS GLOBAL LIFE INSURANCE COMPANY or its authorized representative (collectively “TGI”) as requested. I also authorize TGI to share the information on this form with whatever parties it deems necessary to process my claim. I understand that in executing this authorization, I waive the right for such information to be privileged. I understand that I can withdraw my consent at any time, but that if I do, TGI will not be able to assess my claim and will not pay benefits. A reproduction of this authorization shall be as valid as the original.',
    fallback: {
      part_1: 'Claim form not available.',
      part_2: 'We’re working on bringing this claim form online.',
      part_3: 'Chat with a TGI Claim Advisor at',
      part_4: ', to start this claim.',
    },
    formSteps: {
      part: 'Part',
      step: 'Step',
      partA: 'Part A:',
      partB: 'Part B:',
      partC: 'Part C:',
      partD: 'Part D:',
      partE: 'Part E:',
      saveProgress: 'You can save your progress, and return when you are ready to upload the completed forms.',
      claimantInformation: 'Claimant Information',
      disabilityDetails: 'Disability Details',
      employerStatement: 'Employer’s Statement',
      physicianStatement: 'Physician’s Statement',
      eventInformation: 'Event Information', // milestone
      illnessDetails: 'Illness Details', // critical illness
      spouseInformation: 'Spouse/Executor Information', // life
      deceasedInformation: 'Deceased Information', // life
      lossDetails: 'Loss Details', // property
      propertyDetails: 'Property Details', // property
      employmentInformation: 'Employment Information', // unemployment
      unionStatement: 'Union Statement (if applicable)', // unemployment
    },
    formFields: {
      dateFormat: 'YYYY-MM-DD',
      name: { label: 'Name of Claimant', help: 'First and Last' },
      birthdate: { label: 'Date of Birth', help: 'YYYY-MM-DD' },
      address: { label: 'Mailing Address', help: 'Full address, including city, province and postcode' },
      phone: { label: 'Telephone Number', help: 'Include area code' },
      scanUpload: 'Scan the document and upload in the next step, as a Supporting Document.',
      employerStatement: {
        step1: 'Download this Employer’s Statement form as a PDF file.',
        step2: 'Have the form completed by the Employer.',
        downloadForm: 'Download Employer Form',
      },
      physicianStatement: {
        step1: 'Download this Physician’s Statement form as a PDF file.',
        step2: 'Have the form completed by the attending Physician.',
        downloadForm: 'Download Physician Form',
      },
      unionStatement: {
        step1: 'Download this Union Statement form as a PDF file.',
        step2: 'Have the form completed by a Union Representative.',
        downloadForm: 'Download Union Form',
        unionMember: 'If you are a member of a Union, please have this form completed by your Union representative.',
      },
      propertyStatement: {
        step1: 'Download this Property Loss form as a PDF file.',
        step2: 'List all items included in this claim.',
        downloadForm: 'Download Property Form',
        receipts: 'Sale receipts, invoices and other proof of purchases should be uploaded as supporting documents for all items.'
      },
      disability: {
        event_date: 'Date of Accident/Injury',
      },
      illness: {
        event_date: 'Date of Diagnosis'
      },
      life: {
        spouseName: 'Name',
        spouseBirthdate: 'Date of Birth',
        spouseAddress: {label: 'Mailing Address', help: 'Full address, including city, province and postcode' },
        deceasedName: 'Name of Deceased',
        deathDate: 'Date of Death',
        deathCause: { label: 'Cause of Death', help: 'Please provide details below.' },
        deathDetails: 'Cause of Death (Details)',
      },
      milestone: {
        eventDate: 'Date of Event'
      },
      property: {
        lossType: { label: 'Type of Loss', help: 'Fire, Burglary, etc' },
        lossDate: 'Date of Loss',
        lossDegree: 'Degree of Loss',
        lossAddress: { label: 'Loss occurred at which address?', help: 'Full address, including city, province and postcode' },
        lossReported: 'Loss reported by:',
        lossDetails: { label: 'How did the loss occur?', help: 'Provide details' },
        lossIncident: 'Police or Fire Report Incident Number:',
        lossContact: { label: 'Contact details for Police/Fire representative, department or investigator:', help: 'Include phone number and address' }
      },
      unemployment: {
        employerName: 'Employer’s Name',
        employerAddress: 'Employer’s Address',
        jobTitle: 'Occupation or Job Title',
        unemploymentStartDate: 'Date of Involuntary Unemployment',
        reason: 'Reason for Unemployment',
        causeDetails: 'Please explain:',
        qualifiesForBenefits: { label: 'Do you qualify for unemployment benefits for this period of unemployment?', help: 'If no, please explain.' },
      }
    },
    errors: {
      noAccount: 'Account not selected.',
      noPolicy: 'Policy not selected.',
      noSignature: 'Signature is missing.',
      noClaimType: 'You have not chosen a claim type.',
      docsRequired: 'Supporting documents are required.',
      missingDocs: 'You are missing required documents.',
      pleaseUpload: 'Please upload the required supporting documents.',
      missingFields: 'You are missing required fields.',
      pleaseComplete: 'Please review and complete the highlighted fields.',
    },
    fieldErrors: {
      name: 'Enter your name',
      birthdate: 'Enter your birthdate',
      address: 'Enter your address',
      phone: {
        required: 'Enter your phone number',
        integer: 'Only numbers are allowed',
        length: 'Phone number must be 10 digits'
      },
      eventDate: 'Enter the date when this event took place',
      lossType: 'Enter loss type',
      lossDate: 'Enter loss date',
      lossDegree: 'Enter degree of loss',
      lossAddress: 'Enter loss address',
      lossReport: 'Enter who reported the loss',
      lossDetails: 'Enter loss details',
      lossNumber: 'Enter incident number',
      lossContact: 'Enter contact details',
      diagnosisDate: 'Enter the date of diagnosis',
      injuryDate: 'Enter the date of accident/injury',
      spouseName: 'Enter name of spouse/executor',
      spouseBirthdate: 'Enter your spouse’s birthdate',
      spouseAddress: 'Enter address of spouse/executor',
      deathName: 'Enter deceased name',
      deathDate: 'Enter date of death',
      deathCause: 'Select cause of death',
      deathDetails: 'Enter details for cause of death',
      employerName: 'Enter employer name',
      employerAddress: 'Enter employer address',
      jobTitle: 'Enter your job title',
      unemployDate: 'Enter unemployment date',
      unemployReason: 'Enter unemployment reason',
      chooseOption: 'Choose an option',
      provideReason: 'Provide a reason',
    },
  },
}
